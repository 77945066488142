import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import {
  ABOUT_SELLER_MAKE_AN_OFFER,
  ABOUT_SELLER_CONTACT_SELLER,
  propTypes,
} from '../../util/types';
import { UserCard, Modal, ButtonTabNavHorizontal, MakeAnOfferForm } from '../../components';
// import { Field, Form as FinalForm } from 'react-final-form';
import * as validators from '../../util/validators';
import { currencyConfig } from '../../util/test-data';
import EnquiryForm from './EnquiryForm/EnquiryForm';
import css from './ListingPage.module.css';

const currencyConfigUSD = {
  ...currencyConfig,
  currency: 'USD',
};

const SectionAuthorMaybe = props => {
  const {
    title,
    listing,
    reviews,
    authorDisplayName,
    onContactUser,
    isEnquiryModalOpen,
    onCloseEnquiryModal,
    sendEnquiryError,
    sendEnquiryInProgress,
    onSubmitEnquiry,
    currentUser,
    onManageDisableScrolling,
    onFollowUser,
    onInitiateOfferRequest,
  } = props;

  if (!listing.author) {
    return null;
  }
  const required = validators.required('This field is required');
  const [sellerFormType, setSellerFormType] = useState(ABOUT_SELLER_MAKE_AN_OFFER);

  const showMakeAnOffer = () => {
    setSellerFormType(ABOUT_SELLER_MAKE_AN_OFFER);
  };

  const showOfCustomerReviews = () => {
    setSellerFormType(ABOUT_SELLER_CONTACT_SELLER);
  };

  const handleSubmit = values => {
    console.log('values make an offer form', values);
  };

  const sellerTabs = [
    {
      text: <h3 className={css.sellerTabTitle}>Make an offer</h3>,
      selected: sellerFormType === ABOUT_SELLER_MAKE_AN_OFFER,
      onClick: showMakeAnOffer,
    },
    {
      text: <h3 className={css.sellerTabTitle}>Contact seller</h3>,
      selected: sellerFormType === ABOUT_SELLER_CONTACT_SELLER,
      onClick: showOfCustomerReviews,
    },
  ];

  const userFollowHandler = () => {
    // followers
    const followerId = currentUser?.id;
    // following
    const followingId = listing.author?.id;
    onFollowUser(followerId, followingId);
  };

  return (
    <div id="author" className={css.sectionAuthor}>
      <h2 className={css.aboutProviderTitle}>
        <FormattedMessage id="ListingPage.aboutProviderTitle" />
      </h2>
      <div className={css.sectionAboutSellerWrapper}>
        <UserCard
          user={listing.author}
          reviews={reviews}
          currentUser={currentUser}
          onContactUser={onContactUser}
          onUserFollow={userFollowHandler}
        />
        <div className={css.aboutSellerForms}>
          <ButtonTabNavHorizontal className={css.sellerTabs} tabs={sellerTabs} />
          {sellerFormType === ABOUT_SELLER_MAKE_AN_OFFER ? (
            <div>
              <MakeAnOfferForm
                maxValue={listing.attributes.price.amount}
                currencyConfig={currencyConfigUSD}
                onSubmit={onInitiateOfferRequest}
              />
            </div>
          ) : (
            <div>Contact Seller form</div>
          )}
        </div>
      </div>
      <Modal
        id="ListingPage.enquiry"
        contentClassName={css.enquiryModalContent}
        isOpen={isEnquiryModalOpen}
        onClose={onCloseEnquiryModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <EnquiryForm
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendEnquiryError={sendEnquiryError}
          onSubmit={onSubmitEnquiry}
          inProgress={sendEnquiryInProgress}
        />
      </Modal>
    </div>
  );
};

export default SectionAuthorMaybe;
